import { render, staticRenderFns } from "./slider-index-slide.vue?vue&type=template&id=85db6f12"
import script from "./slider-index-slide.vue?vue&type=script&lang=js"
export * from "./slider-index-slide.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SliderIndexComponentLayer3d: require('/home/d/didisher2/autodrive.spb.ru/www/components/slider-index/component-layer3d.vue').default,SliderIndexSimple: require('/home/d/didisher2/autodrive.spb.ru/www/components/slider-index/simple.vue').default,PictureSlide: require('/home/d/didisher2/autodrive.spb.ru/www/components/picture/picture-slide.vue').default})


import { mapGetters } from "vuex"

export default {
  props: {
    slide: Object
  },
  computed: {
    ...mapGetters({
      settings: "settings/settings/settings"
    })
  }
};
